body {
  font-family: 'Consolas', monospace
}

/* global.css or component-specific CSS */
.sticky-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%; /* Make the text span the entire width of the screen */
  text-align: center; /* Center the text horizontally */
  padding: 1em; /* Optional: padding for spacing */
  font-family: 'Consolas', monospace; /* Set the font to Consolas */
  font-size: x-small;
  background-color: white;
  color: black;
  margin-top: 1em;
  height: 1em;
}

@media screen and (max-width: 480px) {
    .centered {
      display: flex;
      justify-content: center; /* Horizontally center */
      align-items: center;      /* Vertically center */

    }


  }